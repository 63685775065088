export const globalConstants = {
  version: '3.3.88',
  downloadAppLink: 'https://play.google.com/store/apps/details?id=com.goupwards',
  // downloadAppLink: 'https://go-upwards.app.link/getapp', 
  // downloadAppLink: 'https://bit.ly/3rGYmVr',
  contactNumber: '1860-267-4777',
  address: '501-A Pinnacle Corporate Park, BKC Bandra (East), Vill Kole Kalyan,Near Trade Centre Mumbai - 400051',
  prodBaseUrl: 'https://leads.backend.upwards.in',
  // prodBaseUrl: 'http://ec2-13-234-234-182.ap-south-1.compute.amazonaws.com:8080',
  // prodBaseUrl: 'http://ec2-13-234-234-182.ap-south-1.compute.amazonaws.com:9000'
}

export const apiConstants = {
  preSignUpData: {url: '/web/v1/pre_signup/lead/'},
  socialLoginV2: { url: '/web/v2/customer/social_login/' },
  getGamingData: { url: '/web/v2/customer/playground/game/data/'},
  updateGamingResult: {url: '/web/v2/customer/playground/game/submit/'},
  leaderBoardData: {url: '/web/v2/customer/playground/leaderboard/data/'},
  playGroundConfig: {url: '/web/v2/customer/playground/config/'},
  gamingProfile: {url: '/web/v2/customer/playground/profile/data/'}
};

export const eventMapping = {

  PLAYGROUND_SCREEN_VIEWED: 'playground_screen_viewed', 
  PLAYGROUND_QUIZ_GAME_SELECTED: 'playground_quiz_game_selected', 
  PLAYGROUND_STRATEGY_GAME_SELECTED: 'playground_strategy_game_selected', 
  PLAYGROUND_USERPROFILE_TAB_SELECTED: 'playground_userProfile_tab_selected', 
  PLAYGROUND_LEADERBOARD_TAB_SELECTED: 'playground_leaderboard_tab_selected', 
  PLAYGROUND_USER_PROFILE_API_SUCCESS: 'playground_user_profile_api_success', 
  PLAYGROUND_LEADERBOARD_API_SUCCESS: 'playground_leaderboard_api_success', 
  PLAYGROUND_FHR_BANNER_CLICKED: 'playground_fhr_banner_clicked', 

  PLAYGROUND_FHR_DATA_SCREEN_VIEWED: 'playground_fhr_data_screen_viewed', 
  PLAYGROUND_FHR_DATA_SUBMIT_CLICKED: 'playground_fhr_data_submit_clicked',  
  PLAYGROUND_FHR_DATA_SUBMIT_SUCCESS: 'playground_fhr_data_submit_success',  
  PLAYGROUND_FHR_DATA_BACK_BUTTON_CLICKED: 'playground_fhr_data_back_button_clicked',  

  PLAYGROUND_QUIZ_SCREEN_VIEWED: 'playground_quiz_screen_viewed', 
  PLAYGROUND_GAME_CONFIG_API_SUCCESS: 'playground_game_config_api_success', 
  PLAYGROUND_GAME_DATA_API_SUCCESS: 'playground_game_data_api_success', 
  PLAYGROUND_QUIZ_GAME_BACK_CLICKED: 'playground_quiz_game_back_clicked',  
  PLAYGROUND_SELECTED_QUIZ_ID_: 'playground_selected_quiz_id_', 

  PLAYGROUND_SUCCESS_SCREEN_VIEWED: 'playground_success_screen_viewed',  
  PLAYGROUND_LOGIN_DIALOG_VIEWED: 'playground_login_dialog_viewed',  
  PLAYGROUND_CONTINUE_QUIZ_BUTTON_CLICKED: 'playground_continue_quiz_button_clicked',
  PLAYGROUND_EXPLORE_BUTTON_CLICKED: 'playground_explore_button_clicked',
  PLAYGROUND_SUCCESS_LOGIN_BUTTON_CLICKED: 'playground_success_login_button_clicked',
  PLAYGROUND_SOCIAL_LOGIN_API_SUCCESS: 'playground_social_login_api_success',
  PLAYGROUND_GAME_SUBMIT_API_SUCCESS: 'playground_game_submit_api_success',

}